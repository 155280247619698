
import { computed, defineComponent, onMounted, Ref, ref, watch } from 'vue';

import { useRoute } from 'vue-router';

import Store from '@/store';
import Toast from '@/toast';

import ProjectService from '@/services/project.service';

import SVG from '@/components/SVG.vue';
import Layout from '@/components/Layout.vue';
import Loader from '@/components/Loader.vue';
import Table from '@/components/table/Table.vue';
import TableRow from '@/components/table/Row.vue';
import UserInput from '@/components/form/UserInput.vue';
import User from '@/models/user.model';

export default defineComponent({
	components: {
		SVG,
		Layout,
		Loader,
		Table,
		TableRow,
		UserInput,
	},

	setup() {
		const projectId = useRoute().params.id;

		const user = Store.get('user');
		const allUsers = Store.get('users');

		const project = Store.get('project');
		const members: Ref<User[]> = ref([]);

		const loading = ref(false);

		const newBoardMember: Ref<number[]> = ref([]);
		const newStakeholder: Ref<number[]> = ref([]);

		const isAddingBoardMember = ref(false);
		const isAddingStakeholder = ref(false);

		const boardMemberLoader = ref(false);
		const stakeholderLoader = ref(false);

		const board = computed(() => {
			return members.value.filter((user: User) => user.member && user.member.role == 'BOARDMEMBER');
		});

		const stakeholders = computed(() => {
			return members.value.filter((user: User) => user.member && user.member.role == 'STAKEHOLDER');
		});

		const showBoard = computed(() => {
			if (user.value.cannot('update projects') && !board.value.length) {
				return false;
			}

			return true;
		});

		const showStakeholders = computed(() => {
			if (user.value.cannot('update projects') && !stakeholders.value.length) {
				return false;
			}

			return true;
		});

		onMounted(async () => {
			if (project.value?.members) {
				members.value = project.value.members;
				return;
			}

			loading.value = true;

			await ProjectService.show(Number(projectId))
				.then((response) => {
					if (response) {
						Store.set('project', response);

						members.value = response.members;
					}
				})
				.finally(() => {
					loading.value = false;
				});
		});

		watch(newBoardMember, async (value) => {
			if (!value || !value.length) {
				return;
			}

			boardMemberLoader.value = true;

			const user = board.value.find((member: User) => member.id == value[0]);

			if (user) {
				Toast.error('Brúkarin er longu limur');

				isAddingBoardMember.value = false;
				boardMemberLoader.value = false;
				newBoardMember.value = [];

				return;
			}

			const currentUser = allUsers.value.find((user: User) => user.id === value[0]);

			if (!value[0] || !currentUser) {
				Toast.error('Tað eydnaðist ikki leggja brúkaran afturat');
				boardMemberLoader.value = false;
				return;
			}

			await ProjectService.addUser(Number(projectId), value[0], { role: 'BOARDMEMBER' })
				.then((response) => {
					if (response) {
						members.value.push({ ...currentUser, member: { role: 'BOARDMEMBER' } });
					}
				})
				.finally(() => {
					isAddingBoardMember.value = false;
					boardMemberLoader.value = false;
					newBoardMember.value = [];
				});
		});

		watch(newStakeholder, async (value) => {
			if (!value || !value.length) {
				return;
			}

			stakeholderLoader.value = true;

			const user = stakeholders.value.find((stakeholder: User) => stakeholder.id == value[0]);

			if (user) {
				Toast.error('Brúkarin er longu limur');

				isAddingStakeholder.value = false;
				stakeholderLoader.value = false;
				newStakeholder.value = [];

				return;
			}

			const currentUser = allUsers.value.find((user: User) => user.id === value[0]);

			if (!value[0] || !currentUser) {
				Toast.error('Tað eydnaðist ikki leggja brúkaran afturat');
				stakeholderLoader.value = false;
				return;
			}

			await ProjectService.addUser(Number(projectId), value[0], { role: 'STAKEHOLDER' })
				.then((response) => {
					if (response) {
						members.value.push({ ...currentUser, member: { role: 'STAKEHOLDER' } });
					}
				})
				.finally(() => {
					isAddingStakeholder.value = false;
					stakeholderLoader.value = false;
					newStakeholder.value = [];
				});
		});

		// Remove a Member
		async function removeMember(memberId: number) {
			if (!confirm('Ert tú vís/ur?')) {
				return;
			}

			stakeholderLoader.value = true;
			boardMemberLoader.value = true;

			const project = Store.get('project');

			await ProjectService.removeUser(project.value.id, memberId)
				.then((response) => {
					if (response) {
						members.value = members.value.filter((user: User) => user.id != memberId);
					}
				})
				.finally(() => {
					stakeholderLoader.value = false;
					boardMemberLoader.value = false;
				});
		}

		return {
			// data
			members,
			loading,
			newBoardMember,
			newStakeholder,
			isAddingBoardMember,
			isAddingStakeholder,
			boardMemberLoader,
			stakeholderLoader,

			// computed
			board,
			showBoard,
			stakeholders,
			showStakeholders,

			// functions
			removeMember,
		};
	},
});
