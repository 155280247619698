import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex justify-center"
}
const _hoisted_2 = {
  key: 1,
  class: "max-w-screen-lg"
}
const _hoisted_3 = {
  key: 0,
  class: "relative"
}
const _hoisted_4 = { class: "relative group w-1/3 py-3 px-4 font-bold text-blue-400 whitespace-nowrap" }
const _hoisted_5 = { class: "absolute right-0 top-1/2 transform -translate-y-1/2 flex bg-gray-50 opacity-0 group-hover:opacity-100" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "w-1/3 py-3 px-4 whitespace-nowrap" }
const _hoisted_8 = { class: "w-1/3 py-3 px-4 whitespace-nowrap text-right" }
const _hoisted_9 = { colspan: "3" }
const _hoisted_10 = { class: "absolute -left-8 top-1/2 transform -translate-y-1/2" }
const _hoisted_11 = { class: "absolute top-0 left-0 w-full h-full bg-white-500 text-dark-500 font-bold opacity-50 z-20 flex items-center justify-center text-lg" }
const _hoisted_12 = {
  key: 1,
  class: "relative"
}
const _hoisted_13 = { class: "relative group w-1/3 py-3 px-4 font-bold text-blue-400 whitespace-nowrap" }
const _hoisted_14 = { class: "absolute right-0 top-1/2 transform -translate-y-1/2 flex bg-gray-50 opacity-0 group-hover:opacity-100" }
const _hoisted_15 = ["onClick"]
const _hoisted_16 = { class: "w-1/3 py-3 px-4 whitespace-nowrap" }
const _hoisted_17 = { class: "w-1/3 py-3 px-4 whitespace-nowrap text-right" }
const _hoisted_18 = { colspan: "3" }
const _hoisted_19 = { class: "absolute -left-8 top-1/2 transform -translate-y-1/2" }
const _hoisted_20 = { class: "absolute top-0 left-0 w-full h-full bg-white-500 text-dark-500 font-bold opacity-50 z-20 flex items-center justify-center text-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")!
  const _component_SVG = _resolveComponent("SVG")!
  const _component_TableRow = _resolveComponent("TableRow")!
  const _component_UserInput = _resolveComponent("UserInput")!
  const _component_Table = _resolveComponent("Table")!
  const _component_Layout = _resolveComponent("Layout")!
  const _directive_click_away = _resolveDirective("click-away")!

  return (_openBlock(), _createBlock(_component_Layout, null, {
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_Loader, { class: "w-8 h-8 text-blue" })
          ]))
        : _createCommentVNode("", true),
      (!_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_ctx.showBoard)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_Table, {
                    title: "Nevndarlimir",
                    headings: [
						`${_ctx.board.length} ${_ctx.board.length == 1 ? 'Limur' : 'Limir'}`,
						'Teldupostur',
						'Telefonnr.',
					],
                    add: _ctx.$user.can('update projects') ? (_ctx.isAddingBoardMember ? '' : 'Legg afturat') : '',
                    empty: !_ctx.board?.length,
                    onAdd: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isAddingBoardMember = true))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.board, (member) => {
                        return (_openBlock(), _createBlock(_component_TableRow, {
                          key: member.id
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("td", _hoisted_4, [
                              _createTextVNode(_toDisplayString(member.first_name) + " " + _toDisplayString(member.last_name) + " ", 1),
                              _createElementVNode("div", _hoisted_5, [
                                (_ctx.$user.can('update projects'))
                                  ? (_openBlock(), _createElementBlock("div", {
                                      key: 0,
                                      onClick: _withModifiers(($event: any) => (_ctx.removeMember(member.id)), ["stop"]),
                                      class: "w-5 h-5 p-[2px] text-red hover:text-red-700 cursor-pointer",
                                      title: "Remove member"
                                    }, [
                                      _createVNode(_component_SVG, { name: "icons/trash" })
                                    ], 8, _hoisted_6))
                                  : _createCommentVNode("", true)
                              ])
                            ]),
                            _createElementVNode("td", _hoisted_7, _toDisplayString(member.email), 1),
                            _createElementVNode("td", _hoisted_8, _toDisplayString(member.phone), 1)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128)),
                      _withDirectives(_createVNode(_component_TableRow, null, {
                        default: _withCtx(() => [
                          _createElementVNode("td", _hoisted_9, [
                            _createVNode(_component_UserInput, {
                              modelValue: _ctx.newBoardMember,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newBoardMember) = $event)),
                              onUpdate: _cache[1] || (_cache[1] = (event) => (_ctx.newBoardMember = event)),
                              setFocus: _ctx.isAddingBoardMember,
                              borderless: true,
                              noMargin: true,
                              class: "ml-7"
                            }, null, 8, ["modelValue", "setFocus"])
                          ])
                        ]),
                        _: 1
                      }, 512), [
                        [_vShow, _ctx.isAddingBoardMember],
                        [_directive_click_away, () => (_ctx.isAddingBoardMember = false)]
                      ])
                    ]),
                    _: 1
                  }, 8, ["headings", "add", "empty"]),
                  _withDirectives(_createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_Loader, { class: "w-4 h-4" })
                  ], 512), [
                    [_vShow, _ctx.boardMemberLoader]
                  ]),
                  _withDirectives(_createElementVNode("div", _hoisted_11, null, 512), [
                    [_vShow, _ctx.boardMemberLoader]
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.showStakeholders)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createVNode(_component_Table, {
                    title: "Áhugapartar",
                    headings: [
						`${_ctx.stakeholders.length} ${_ctx.stakeholders.length == 1 ? 'Limur' : 'Limir'}`,
						'Teldupostur',
						'Telefonnr.',
					],
                    add: _ctx.$user.can('update projects') ? (_ctx.isAddingStakeholder ? '' : 'Legg afturat') : '',
                    empty: !_ctx.stakeholders?.length,
                    onAdd: _cache[5] || (_cache[5] = ($event: any) => (_ctx.isAddingStakeholder = true))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stakeholders, (member) => {
                        return (_openBlock(), _createBlock(_component_TableRow, {
                          key: member.id
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("td", _hoisted_13, [
                              _createTextVNode(_toDisplayString(member.first_name) + " " + _toDisplayString(member.last_name) + " ", 1),
                              _createElementVNode("div", _hoisted_14, [
                                (_ctx.$user.can('update projects'))
                                  ? (_openBlock(), _createElementBlock("div", {
                                      key: 0,
                                      onClick: _withModifiers(($event: any) => (_ctx.removeMember(member.id)), ["stop"]),
                                      class: "w-5 h-5 p-[2px] text-red hover:text-red-700 cursor-pointer",
                                      title: "Remove member"
                                    }, [
                                      _createVNode(_component_SVG, { name: "icons/trash" })
                                    ], 8, _hoisted_15))
                                  : _createCommentVNode("", true)
                              ])
                            ]),
                            _createElementVNode("td", _hoisted_16, _toDisplayString(member.email), 1),
                            _createElementVNode("td", _hoisted_17, _toDisplayString(member.phone), 1)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128)),
                      _withDirectives(_createVNode(_component_TableRow, null, {
                        default: _withCtx(() => [
                          _createElementVNode("td", _hoisted_18, [
                            _createVNode(_component_UserInput, {
                              modelValue: _ctx.newStakeholder,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newStakeholder) = $event)),
                              onUpdate: _cache[4] || (_cache[4] = (event) => (_ctx.newStakeholder = event)),
                              setFocus: _ctx.isAddingStakeholder,
                              borderless: true,
                              noMargin: true,
                              class: "ml-7"
                            }, null, 8, ["modelValue", "setFocus"])
                          ])
                        ]),
                        _: 1
                      }, 512), [
                        [_vShow, _ctx.isAddingStakeholder],
                        [_directive_click_away, () => (_ctx.isAddingStakeholder = false)]
                      ])
                    ]),
                    _: 1
                  }, 8, ["headings", "add", "empty"]),
                  _withDirectives(_createElementVNode("div", _hoisted_19, [
                    _createVNode(_component_Loader, { class: "w-4 h-4" })
                  ], 512), [
                    [_vShow, _ctx.stakeholderLoader]
                  ]),
                  _withDirectives(_createElementVNode("div", _hoisted_20, null, 512), [
                    [_vShow, _ctx.stakeholderLoader]
                  ])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}